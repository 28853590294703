import { mapState } from "vuex"

export default {
  computed: {
    ...mapState({
      stops: state => state.gtfs.stops,
      routes: state => state.gtfs.routes,
      settings: state => state.settings,
    }),
  },
  methods: {
    getCSVTitle() {
      const scopeName = this.$route?.meta?.scope ?? this.$route.name
      const now = new Date()

      const year = now.getFullYear()
      const month = this.padLeft(now.getMonth() + 1)
      const date = this.padLeft(now.getDate())
      const hour = this.padLeft(now.getHours())
      const minute = this.padLeft(now.getMinutes())
      const second = this.padLeft(now.getSeconds())

      const formated = `${date}-${month}-${year}_${hour}-${minute}-${second}`

      return `${scopeName}_${formated}`
    },

    getCSVButtonText() {
      const scopeName = this.$route?.meta?.scope ?? this.$route.name

      return `Export ${scopeName} to CSV`
    },

    getCSVLabels(columnMap) {
      const labels = {}

      columnMap.forEach(column => {
        const key = column.value.replace(/\./g, "_")
        labels[key] = { title: column.text }
      })

      return labels
    },

    getCSVData(columnMap, rawData, sortBy = "", sortDesc = false) {
      let csvData = []
      rawData.forEach(data => {
        const csvRowData = {}
        columnMap.forEach(column => {
          const key = column.value.replace(/\./g, "_")
          let value

          if (column.value.includes(".")) {
            const valueSplitKey1 = column.value.split(".")[0]
            const valueSplitKey2 = column.value.split(".")[1]

            value = data[valueSplitKey1][valueSplitKey2] ?? ""
          } else {
            value = data[column.value] ?? ""
          }

          if (column.conversionFunc) {
            switch (column.conversionFunc) {
              case "boolNice":
                value = this.boolNice(value)
                break
              case "formatDateTimeStamp":
                value = this.formatDateTimeStamp(value)
                break
              case "emptyNice":
                value = this.emptyNice(value, column.emptyTrueDefault ?? "Unknown")
                break
              default:
            }
          }

          if (column.customFunc) {
            let additionalValue

            if (column.additionalValue) {
              if (column.additionalValue.includes(".")) {
                const additionalValueSplitKey1 = column.additionalValue.split(".")[0]
                const additionalValueSplitKey2 = column.additionalValue.split(".")[1]
                additionalValue = data[additionalValueSplitKey1]
                  ? data[additionalValueSplitKey1][additionalValueSplitKey2] ?? ""
                  : ""
              } else {
                additionalValue = data[column.additionalValue] ?? ""
              }
            }

            let additionalValue2

            if (column.additionalValue2) {
              if (column.additionalValue2.includes(".")) {
                const additionalValue2SplitKey1 = column.additionalValue2.split(".")[0]
                const additionalValue2SplitKey2 = column.additionalValue2.split(".")[1]
                additionalValue2 = data[additionalValue2SplitKey1]
                  ? data[additionalValue2SplitKey1][additionalValue2SplitKey2] ?? ""
                  : ""
              } else {
                additionalValue2 = data[column.additionalValue2] ?? ""
              }
            }

            switch (column.customFunc) {
              case "getOnTrip":
                value = this.getOnTrip(value, additionalValue)
                break
              case "getRichStopName":
                value = this.getRichStopName(value, additionalValue, additionalValue2)
                break
              case "getAdditionalInfo":
                value = this.getAdditionalInfo(value, additionalValue)
                break
              case "getRichStopID":
                value = this.getRichStopID(value, additionalValue)
                break
              case "getServiceMode":
                value = this.getServiceMode(value)
                break
              case "getNoteFormattedIdentifier":
                value = this.getNoteFormattedIdentifier(value, additionalValue)
                break
              case "getDeviceStatus":
                value = this.getDeviceStatus(value)
                break
              case "getPeakVolume":
                value = this.getOffPeakVolume(value, additionalValue)
                break
              case "getOffPeakVolume":
                value = this.getOffPeakVolume(value, additionalValue)
                break
              case "getQuietVolume":
                value = this.getQuietVolume(value, additionalValue)
                break
              // eslint-disable-next-line no-fallthrough
              default:
            }
          }
          csvRowData[key] = value
        })

        csvData.push(csvRowData)
      })

      /**
       * Overrides default sorting by table headers.
       */
      function customSort(items) {
        const sortField = sortBy
        const sortDescending = sortDesc

        items.sort((a, b) => {
          let sortA = null
          let sortB = null

          // Handle sorting based on clicked header column
          switch (sortField) {
            // Notes list custom sorting
            case "convertCreatedDate": {
              sortA = a.created && a.created !== "Unknown" ? a.created : ""
              sortB = b.created && b.created !== "Unknown" ? b.created : ""

              break
            }
            // Notes list custom sorting
            case "priority": {
              // Order by priority not alphabetically
              const priorityOrder = {
                urgent: 1,
                high: 2,
                medium: 3,
                low: 4,
              }

              sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
              sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

              break
            }
            // Stop list custom sorting
            case "zone_id": {
              sortA = a.zone_id ? parseFloat(a.zone_id.split("/").join(".")) : 0
              sortB = b.zone_id ? parseFloat(b.zone_id.split("/").join(".")) : 0

              break
            }
            // Vehicles list custom sorting
            case "device_id": {
              sortA = a.status_device_id
              sortB = b.status_device_id

              break
            }
            // Vehicles list custom sorting
            case "device_status": {
              sortA = a.status_status ? a.status_status : "unknown"
              sortB = b.status_status ? b.status_status : "unknown"

              break
            }
            // Vehicles list custom sorting
            case "peak_volume": {
              sortA = a.config_peak_volume
              sortB = b.config_peak_volume

              break
            }
            // Vehicles list custom sorting
            case "offpeak_volume": {
              sortA = a.config_offpeak_volume
              sortB = b.config_offpeak_volume

              break
            }
            // Vehicles list custom sorting
            case "quiet_volume": {
              sortA = a.config_quiet_volume
              sortB = b.config_quiet_volume

              break
            }
            // Vehicles list custom sorting
            case "operator": {
              sortA = a.config_operator || ""
              sortB = b.config_operator || ""

              break
            }
            // Devices list custom sorting
            case "formattedVehicleId": {
              sortA = a.config_vehicle_id ? a.config_vehicle_id : "Unknown"
              sortB = b.config_vehicle_id ? b.config_vehicle_id : "Unknown"

              break
            }
            // Devices list custom sorting
            case "convertPositionDate": {
              sortA =
                a.status_position_timestamp && a.status_position_timestamp !== "Unknown"
                  ? a.status_position_timestamp
                  : "Unknown"
              sortB =
                b.status_position_timestamp && b.status_position_timestamp !== "Unknown"
                  ? b.status_position_timestamp
                  : "Unknown"

              break
            }
            // Devices list custom sorting
            case "config.ssd_serial": {
              sortA = a.config_ssd_serial
              sortB = b.config_ssd_serial

              break
            }
            // Devices list custom sorting
            case "case_id": {
              sortA = a.config_case_id
              sortB = b.config_case_id

              break
            }
            // Devices list custom sorting
            case "config.sim_id": {
              sortA = a.config_sim_id
              sortB = b.config_sim_id

              break
            }
            // Devices list custom sorting
            case "release_version": {
              sortA = a.status_release_version
              sortB = b.status_release_version

              break
            }
            // Devices list custom sorting
            case "os_version": {
              sortA = a.status_os_version
              sortB = b.status_os_version

              break
            }
            // Route list custom sorting
            case "route_short_name": {
              sortA = a.route_short_name
              sortB = b.route_short_name

              return sortA - sortB
            }
            default: {
              // Default sorting
              sortA = a[sortBy]
              sortB = b[sortBy]

              break
            }
          }

          if (sortA < sortB) {
            return -1
          }

          if (sortA > sortB) {
            return 1
          }

          return 0
        })

        // Reverse order if header clicked again
        if (sortDescending) {
          items.reverse()
        }

        return items
      }

      if (sortBy) {
        csvData = customSort(csvData)
      }

      return csvData
    },

    // Helper function to add leading 0 for minutes, hours, etc. ie. 3 => 03, 9 => 09
    padLeft(mum, base, chr) {
      const len = String(base || 10).length - String(mum).length + 1

      return len > 0 ? new Array(len).join(chr || "0") + mum : mum
    },

    // Helper function to format dateTime stamp
    formatDateTimeStamp(dateTimeStamp, format = "sv") {
      if (!dateTimeStamp) {
        return "Unknown"
      }

      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
      const date = iso8601Regex.test(dateTimeStamp)
        ? new Date(dateTimeStamp)
        : new Date(parseInt(dateTimeStamp, 10) * 1000)

      return date.toLocaleString(format)
    },

    // Helper function to convert boolean value to "Yes"/"No" literal nicely
    boolNice(bool) {
      return bool ? "Yes" : "No"
    },

    emptyNice(value, defaultEmptyValue) {
      if (!value) {
        return defaultEmptyValue
      }

      return value
    },

    // Helper function for customising on_trip of a vehicle item
    getOnTrip(status_trip, rtData_trip) {
      const onTrip = status_trip?.trip_id ? "Yes" : "No"
      // eslint-disable-next-line no-nested-ternary
      const richText = status_trip?.trip_id
        ? ` (OBA: ${status_trip.trip_id})`
        : rtData_trip?.trip_id
        ? ` (GTFS-RT: ${rtData_trip.trip_id})`
        : ""

      return `${onTrip}${richText}`
    },

    // Helper function for customising stop name with its info on latitude, longitude information
    getRichStopName(stopName, stopLat, stopLon) {
      if (stopLat && stopLon) {
        return `${stopName} @(${stopLat}, ${stopLon})`
      }

      return stopName
    },

    getAdditionalInfo(configAdditionalText, configAdditionalTTS) {
      return configAdditionalText || configAdditionalTTS ? "Yes" : "No"
    },

    getRichStopID(stopID, parentStation) {
      if (parentStation) {
        return `${stopID}, Parent Station: ${parentStation}`
      }

      return stopID
    },

    getServiceMode(mode) {
      let serviceMode

      switch (`${mode}`) {
        case "0":
        case "1":
        case "2":
          serviceMode = "train"
          break
        case "3":
          serviceMode = "bus"
          break
        case "4":
          serviceMode = "ferry"
          break
        case "5":
          serviceMode = "cable-car"
          break
        case "6":
        case "712":
          serviceMode = "school"
          break
        default:
          serviceMode = "other"
      }

      return serviceMode
    },

    getDeviceStatus(status) {
      return status === "idle" || status === "updating" ? "online" : "offline"
    },

    getPeakVolume(volume, override) {
      if (override) {
        return volume
      }

      return this.settings.peak_volume
    },

    getOffPeakVolume(volume, override) {
      if (override) {
        return volume
      }

      return this.settings.offpeak_volume
    },

    getQuietVolume(volume, override) {
      if (override) {
        return volume
      }

      return this.settings.quiet_volume
    },

    getNoteFormattedIdentifier(foreignID, noteType) {
      let id = foreignID

      switch (noteType) {
        case "Device":
          id = `Device ID: ${id}`
          break
        case "Route":
          id = this.getRouteTitle(foreignID)
          break
        case "Stop":
          id = this.getStopTitle(foreignID)
          break
        default:
      }

      return id
    },

    getRouteTitle(routeId) {
      const route = this.routes.find(r => r.route_id === parseInt(routeId, 10))

      return route && route.route_short_name && route.route_long_name
        ? `${route.route_short_name} - ${route.route_long_name}`
        : "Unknown"
    },

    getStopTitle(stopId) {
      const stop = this.stops.find(s => s.stop_id === stopId)

      return stop && stop.stop_code && stop.stop_name
        ? `${stop.stop_code} - ${stop.stop_name}`
        : "Unknown"
    },
  },
}
