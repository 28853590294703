<script>
import csvHelpers from "@/mixins/csvHelpers"

export default {
  name: "CSVExportButton",
  mixins: [csvHelpers],
  props: {
    columnMap: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <v-row class="pb-4 pt-0 ma-0">
    <v-col cols="12" sm="2">
      <vue-json-to-csv
        :json-data="getCSVData(columnMap, items, sortBy, sortDesc)"
        :labels="getCSVLabels(columnMap)"
        :csv-title="getCSVTitle()"
      >
        <button
          type="button"
          class="v-btn v-btn--outlined theme--light v-size--default secondary--text"
          :class="{ 'v-btn--disabled': !items || items.length === 0 }"
          :disabled="!items || items.length === 0"
        >
          <span class="v-btn__content">{{ getCSVButtonText() }}</span>
        </button>
      </vue-json-to-csv>
    </v-col>
  </v-row>
</template>
